<template>
	<section class="domain_content">
		<!-- privacy policy -->
		<div class="sub-page">
			<h1>Privacy Policy</h1>
			<p class="small">Last updated: September 18, 2024</p>
			<p>
This Privacy Policy explains our practices regarding the collection, use, and disclosure of your personal information when you use our Service. It also outlines your privacy rights and how the law protects you. <br>
We collect and use your personal data to provide and improve the Service. By using the Service, you agree to the collection and use of your information as described in this Privacy Policy.
			</p>
			<div class="part">
				<h2>
				Security of Your Personal Data
				</h2>
				<p>
					We prioritize the protection of your personal data. However, please note that no method of transmission over the Internet or electronic storage is completely secure. While we employ commercially reasonable measures to safeguard your information, we cannot guarantee absolute security.
				</p>
				<h2>
				Links to Third-Party Websites
				</h2>
				<p>
					Our Service may contain links to third-party websites that are not operated by us. If you click on these links, you will be redirected to the respective third-party site. We strongly encourage you to review the privacy policies of any site you visit, as we do not control, and are not responsible for, the content, privacy practices, or operations of third-party sites or services.
				</p>
			</div>
		</div>
	</section>
</template>
  
  <script>
import '@/css/main.scss'
const title = 'Privacy Policy'
const description = 'Our Privacy Policy explains the operation of users\' data and will keep updates.'
export default {
	metaInfo: {
		title,
		meta: [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'title',
				content: title,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
		],
	},
}
</script>
  